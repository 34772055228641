import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import "./Navbar.scss";

import { AuthContext } from "../../context/auth-context";
import Dashboard from "@mui/icons-material/Dashboard";
import { Link, useNavigate } from "react-router-dom";
import NotificationsPopover from "./NotificationsPopover/NotificationsPopover";
import MenuNotifications from "./MenuNotifications/MenuNotifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useHttpClient } from "../../hooks/http-hook";
import { ClinicaContext } from "../../context/clinica-context";
//Material DropDown Menu
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useWebSocket from "../../hooks/use-websocket";
/////////////////////////////////////////////

function Navbar({
  title = "Pagina",
  icon = Dashboard,
  paths = [],
  btn = false,
  iconBtn = "",
  medicos,
  avaliacaoDispensada = null,
}) {
  const [isOnline, setIsOnline] = useState(true);
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const Icon = icon;
  const BtnIcon = iconBtn;
  const navigate = useNavigate();
  const [openNotifications, setOpenNotifications] = useState(false);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [notificacoes, setNotificacoes] = useState([]);
  const lastNotificationIdRef = useRef(null);

  const logout = () => {
    navigate("/");
    auth.logout();
  };

  // async function showNotification(mensagem) {
  //   if ("Notification" in window) {
  //     const permission = await Notification.requestPermission();
  //     if (permission === "granted") {
  //       new Notification("HealthTech", {
  //         body: mensagem,
  //         icon: iconNotificacao,
  //       });
  //     }
  //   }
  // }

  //Verificar conectividade com a internet
  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  useEffect(() => {
    const fetchNotificacoes = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/notificacoes/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setNotificacoes(
          responseData.notificacoes.filter((n) => n.user === auth.userId)
        );
      } catch (err) {}
    };
    fetchNotificacoes();
  }, [clinica, sendRequest, auth]);

  //Material UI MENU CODE
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  ////////////////////

  const btnOnClickHandler = () => {
    if (btn.link) {
      navigate(btn.link);
    }
  };

  //CODIGO NOTIFICACAO
  const handleDispensarNotificacao = useCallback(
    async (idNotificacao) => {
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/notificacoes/${idNotificacao}`,
          "DELETE",
          {},
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        let tempNotificacoes = [...notificacoes];
        const notificacaoFiltrada = tempNotificacoes.filter(
          (n) => n.id === idNotificacao
        )[0];

        tempNotificacoes = tempNotificacoes.filter(
          (n) => n.id !== idNotificacao
        );
        setNotificacoes(tempNotificacoes);
        if (notificacaoFiltrada.tipo === "avaliação" && avaliacaoDispensada) {
          avaliacaoDispensada(notificacaoFiltrada.id);
        }
      } catch (err) {}
    },
    [auth, notificacoes, sendRequest, avaliacaoDispensada]
  );

  const handleApagarTodasNotificacoes = async () => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/notificacoes/todas/${auth.userId}`,
        "DELETE",
        {},
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      setNotificacoes([]);
    } catch (err) {
      console.error("err", err);
    }
  };
  const handleClickNotificacao = useCallback(
    async (idNotificacao, tipo, idCliente) => {
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/notificacoes/${idNotificacao}`,
          "DELETE",
          {},
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      let tempNotificacoes = notificacoes;
      tempNotificacoes = tempNotificacoes.filter((n) => n.id !== idNotificacao);
      setNotificacoes(tempNotificacoes);

      switch (tipo) {
        case "espera avaliação":
          navigate(`/clientes/${idCliente}-avaliacao`);
          break;
        case "espera procedimento":
          navigate(`/clientes/${idCliente}-historico`);
          break;
        case "espera consulta":
          navigate(`/clientes/${idCliente}`);
          break;
        case "efetuou avaliação":
          navigate(`/clientes/${idCliente}-avaliacao`);
          break;
        default:
          navigate(`/clientes/${idCliente}`);
      }
    },
    [navigate, auth.token, sendRequest, notificacoes]
  );

  const handleCloseNotification = useCallback(() => {
    setOpenNotifications(false);
  }, []);

  const handleOpenNotifications = useCallback((e) => {
    setAnchorElNotifications(e.currentTarget);
    setOpenNotifications(true);
  }, []);
  ///////////////////////////////////////////////

  const handleMessage = useCallback(
    (parsedSocketData) => {
      if (
        parsedSocketData.tabela === "notificacaos" &&
        parsedSocketData.documento.user.includes(auth.userId)
      ) {
        if (parsedSocketData.documento._id !== lastNotificationIdRef.current) {
          lastNotificationIdRef.current = parsedSocketData.documento._id;

          const fetchcliente = async () => {
            try {
              const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/${parsedSocketData.documento.cliente}`,
                "GET",
                null,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );

              let mensagemNaNotificacao = "";

              if (
                parsedSocketData.tabela === "notificacaos" &&
                parsedSocketData.tipoDeOperacao === "insert"
              ) {
                if (
                  (auth.role === "Secretária(o)" ||
                    auth.role === "Assistente") &&
                  parsedSocketData.documento.tipo === "efetuou avaliação"
                ) {
                  mensagemNaNotificacao = `${responseData.cliente.nome} Efetuou uma avaliação.`;
                }

                if (
                  auth.role === "Dentista" ||
                  auth.role === "Médico/Administrador"
                ) {
                  mensagemNaNotificacao = parsedSocketData.documento.mensagem;
                }
              }

              setNotificacoes((current) => [
                ...current,
                {
                  cliente: {
                    id: parsedSocketData.documento.cliente,
                    _id: parsedSocketData.documento.cliente,
                    nome: responseData.cliente.nome,
                    foto: responseData.cliente.foto,
                  },
                  tipo: parsedSocketData.documento.tipo,
                  id: parsedSocketData.documento._id,
                  mensagem: mensagemNaNotificacao,
                },
              ]);

              // await showNotification(mensagemNaNotificacao);
            } catch (err) {
              console.error(err);
            }
          };

          fetchcliente();
        }
      }
    },
    [auth, sendRequest]
  );

  useWebSocket(process.env.REACT_APP_WEBSOCKET_ADDRESS, handleMessage, [
    auth,
    sendRequest,
  ]);

  const handleMedicoChange = (e) => {
    navigate(`../${e.target.value}`);
  };

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="navbar__title">
          <Icon style={{ color: "#175c93", fontSize: "25px" }} />
          <span className="navbar__title__title">{title}</span>
          {medicos && (
            <select
              className="navbar__select-medicos"
              onChange={handleMedicoChange}
            >
              {medicos.map((m) => (
                <option value={m.id} key={m.id}>
                  {m.nome}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="mensagensImportantes">
          <span>{!isOnline && "Sem Conexão à Internet!"}</span>
        </div>
        <div className="items">
          {/* {notificacoes && ( */}
          <div className="item" style={{ cursor: "pointer" }}>
            <NotificationsPopover
              nrNotificacoes={notificacoes.length}
              onClick={handleOpenNotifications}
              anchorEl={anchorElNotifications}
            />
            {/* {notificacoes.length > 0 && ( */}
            <MenuNotifications
              anchorEl={anchorElNotifications}
              handleCloseNotification={handleCloseNotification}
              openNotification={openNotifications}
              notificacoes={notificacoes}
              handleDispensarNotificacao={handleDispensarNotificacao}
              handleClickNotificacao={handleClickNotificacao}
              handleApagarTodasNotificacoes={handleApagarTodasNotificacoes}
            />
            {/* )} */}
          </div>
          {/* )} */}
          <div className="navbar__item__userInfo">
            <img
              src={
                auth.image
                  ? `${process.env.REACT_APP_BACKEND_LINK}/${auth.image}`
                  : "/images/fallBackPicture.png"
              }
              alt=""
              className="avatar"
            />
            <div className="navbar__item__userInfo__nomeRole">
              <span className="navbar__item__userInfo__nomeRole__nome">
                {auth.nome}
              </span>
              <span className="navbar__item__userInfo__nomeRole__role">
                {auth.role}
              </span>
            </div>
            <KeyboardArrowDownIcon
              className="navbar__dropdownIcon"
              style={{ cursor: "pointer" }}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => navigate(`/users/${auth.userId}/perfil`)}
              >
                Perfil
              </MenuItem>
              <MenuItem onClick={logout}>Sair</MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      {paths.length > 0 && (
        <div className="navbar__path__wrapper">
          <div className="navbar__path">
            {paths.map((path, i) =>
              i === 0 ? (
                <Link to={path.link} key={i}>
                  <span className="navbar__path">{path.nome}</span>
                </Link>
              ) : (
                <div className="navbar__path-utility-div" key={i}>
                  <span className="navbar__icon__front">{">"}</span>
                  {i === paths.length - 1 ? (
                    <span className="navbar__path navbar__path__last">
                      {path.nome}
                    </span>
                  ) : (
                    <Link to={path.link}>
                      <span className="navbar__path">{path.nome}</span>
                    </Link>
                  )}
                </div>
              )
            )}
          </div>

          {btn && (
            <div className="navbar__buttons">
              <div className="big-btn">
                <BtnIcon className="big-btn-icon" />
                <span className="big-btn-text" onClick={btnOnClickHandler}>
                  {btn.text}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Navbar;
