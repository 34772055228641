import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
//css
import "./Single.scss";
//Components
import Navbar from "../../../shared/components/navbar/Navbar";
import Sidebar from "../../../shared/components/sidebar/Sidebar";

import PdfOrcamentoInpsWrapper from "../../../shared/components/PdfGenerator/PdfOrcamento/PdfOrcamentoInps/PdfOrcamentoInpsWrapper";
import PdfOrcamentoParticularWrapper from "../../../shared/components/PdfGenerator/PdfOrcamento/PdfOrcamentoParticular/PdfOrcamentoParticularWrapper";
import PdfPrescricaoWrapper from "../../../shared/components/PdfGenerator/PdfPrescricao/PdfPrescricaoWrapper";
import PdfAtestadoWrapper from "../../../shared/components/PdfGenerator/PdfAtestado/PdfAtestadoWrapper";
import FluxoAtendimento from "./components/FluxoAtendimento/FluxoAtendimento";
import PdfFaturaWrapper from "../../../shared/components/PdfGenerator/PdfFatura/PdfFaturaWrapper";
import PdfImpressoWrapper from "../../../shared/components/PdfGenerator/PdfImpresso/PdfImpressoWrapper";
//Custom Hooks
import { useHttpClient } from "../../../shared/hooks/http-hook";

//MUI Icons
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
//Context
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import Debitos from "./components/Debitos/Debitos";
import InfoGeral from "./components/InfoGeral/InfoGeral";
import Documentos from "./components/Documentos/Documentos";
import Ficheiros from "./components/Ficheiros/Ficheiros";
import Historico from "./components/Historico/Historico";
import PdfReciboWrapper from "../../../shared/components/PdfGenerator/PdfRecibo/PdfReciboWrapper";
import { useLoading } from "../../../shared/context/LoadingContext";

function Single() {
  const { startLoading, stopLoading } = useLoading();

  const [userData, setUserData] = useState();
  const [orcamentoData, setOrcamentoData] = useState({ show: false });
  const [prescricaoData, setPrescricaoData] = useState({ show: false });
  const [reciboData, setReciboData] = useState({ show: false });
  const [atestadoData, setAtestadoData] = useState({ show: false });
  const [impressoData, setImpressoData] = useState({ show: false });
  const [faturaData, setFaturaData] = useState({ show: false });
  const [showMainTabs, setShowMainTabs] = useState("");

  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  const temporary = useParams().clienteId;
  const clienteId = temporary.split("-")[0];

  useEffect(() => {
    //Caso na barra de enderecos conter plano de tratamento, orcamento ou avaliacao,
    //A aba de fluxo de Atendimento irá ficar aberta
    //Dentro de useEffect para evitar renders infinitos
    const aba = temporary.split("-")[1];
    if (aba === "avaliacao" || aba === "planotratamento" || aba === "orcamento")
      setShowMainTabs("fluxoAtendimento");
    else if (aba === "debitos") setShowMainTabs("debitos");
    else if (aba === "documentos") setShowMainTabs("documentos");
    else if (aba === "ficheiros") setShowMainTabs("ficheiros");
    else if (aba === "historico") setShowMainTabs("historico");
    else if (aba === "debitos") setShowMainTabs("debitos");
    else setShowMainTabs("infoGeral");
  }, [temporary]);

  useEffect(() => {
    const fetchCliente = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/comanamnese/${clienteId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        // const responseData2 = await sendRequest(
        //   `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/findcontactoscomespacos/${clinica.clinica._id}`,
        //   "GET",
        //   null,
        //   {
        //     Authorization: "Bearer " + auth.token,
        //   }
        // );
        // console.log("responsedata2", responseData2);

        setUserData(responseData.cliente);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchCliente();
  }, [sendRequest, clienteId, clinica, auth]);

  const dadosOrcamento = (dados) => {
    setOrcamentoData({ ...dados, show: true });
  };

  const dadosPrescricao = (dados) => {
    setPrescricaoData({ ...dados, show: true });
  };

  const dadosAtestado = (dados) => {
    setAtestadoData({ ...dados, show: true });
  };

  const dadosImpresso = (dados) => {
    setImpressoData({ ...dados, show: true });
  };

  const dadosFatura = (dados) => {
    setFaturaData({ ...dados, show: true });
  };

  const dadosRecibo = (dados) => {
    setReciboData({ ...dados, show: true });
  };

  const showSinglePage = () => {
    if (orcamentoData.show)
      setOrcamentoData((current) => {
        return { ...current, show: false };
      });

    if (prescricaoData.show)
      setPrescricaoData((current) => {
        return { ...current, show: false };
      });

    if (impressoData.show)
      setImpressoData((current) => {
        return { ...current, show: false };
      });

    if (atestadoData.show)
      setAtestadoData((current) => {
        return { ...current, show: false };
      });

    if (faturaData.show)
      setFaturaData((current) => {
        return { ...current, show: false };
      });

    if (reciboData.show)
      setReciboData((current) => {
        return { ...current, show: false };
      });
  };

  /////////
  return (
    <>
      {orcamentoData.show && orcamentoData.tipo === "INPS" && (
        <PdfOrcamentoInpsWrapper
          showSinglePage={showSinglePage}
          orcamentoData={orcamentoData}
        />
      )}

      {reciboData.show && (
        <PdfReciboWrapper
          showPreviousPage={showSinglePage}
          reciboData={reciboData}
        />
      )}

      {orcamentoData.show && orcamentoData.tipo === "Particular" && (
        <PdfOrcamentoParticularWrapper
          showSinglePage={showSinglePage}
          orcamentoData={orcamentoData}
        />
      )}

      {prescricaoData.show && (
        <PdfPrescricaoWrapper
          showPreviousPage={showSinglePage}
          prescricaoData={prescricaoData}
        />
      )}

      {atestadoData.show && (
        <PdfAtestadoWrapper
          showPreviousPage={showSinglePage}
          atestadoData={atestadoData}
        />
      )}

      {impressoData.show && (
        <PdfImpressoWrapper
          showPreviousPage={showSinglePage}
          impressoData={impressoData}
        />
      )}

      {faturaData.show && (
        <PdfFaturaWrapper
          showPreviousPage={showSinglePage}
          faturaData={faturaData}
        />
      )}

      {!orcamentoData.show &&
        !prescricaoData.show &&
        !atestadoData.show &&
        !impressoData.show &&
        !faturaData.show &&
        !reciboData.show && (
          <>
            {userData && (
              <div className="single">
                <Sidebar />
                <div className="singleContainer">
                  <Navbar
                    title={userData.nome}
                    icon={PersonOutlineOutlinedIcon}
                    iconBtn={BorderColorOutlinedIcon}
                  />

                  <div className="single__abas-principais single__abas-principais--cliente">
                    <span
                      className={`single__abas-principais__aba ${
                        showMainTabs === "infoGeral" &&
                        "single__abas-principais__aba--active"
                      }`}
                      onClick={() => setShowMainTabs("infoGeral")}
                    >
                      Info Geral
                    </span>
                    {(auth.perm.includes("r-aval") ||
                      auth.perm.includes("r-orc") ||
                      auth.perm.includes("r-plt")) && (
                      <span
                        className={`single__abas-principais__aba ${
                          showMainTabs === "fluxoAtendimento" &&
                          "single__abas-principais__aba--active"
                        }`}
                        onClick={() => setShowMainTabs("fluxoAtendimento")}
                      >
                        Fluxo Atendimento
                      </span>
                    )}
                    {auth.perm.includes("r-deb") && (
                      <span
                        className={`single__abas-principais__aba ${
                          showMainTabs === "debitos" &&
                          "single__abas-principais__aba--active"
                        }`}
                        onClick={() => setShowMainTabs("debitos")}
                      >
                        Débitos
                      </span>
                    )}

                    <span
                      className={`single__abas-principais__aba ${
                        showMainTabs === "historico" &&
                        "single__abas-principais__aba--active"
                      }`}
                      onClick={() => setShowMainTabs("historico")}
                    >
                      Histórico
                    </span>

                    {(auth.perm.includes("r-pres") ||
                      auth.perm.includes("r-ates")) && (
                      <span
                        className={`single__abas-principais__aba ${
                          showMainTabs === "documentos" &&
                          "single__abas-principais__aba--active"
                        }`}
                        onClick={() => setShowMainTabs("documentos")}
                      >
                        Documentos
                      </span>
                    )}
                    {auth.perm.includes("r-anex") && (
                      <span
                        className={`single__abas-principais__aba ${
                          showMainTabs === "ficheiros" &&
                          "single__abas-principais__aba--active"
                        }`}
                        onClick={() => setShowMainTabs("ficheiros")}
                      >
                        Ficheiros
                      </span>
                    )}
                  </div>

                  <div className="singleContainer__all">
                    {showMainTabs === "infoGeral" &&
                      auth.perm.includes("r-cli") && (
                        <InfoGeral
                          clienteId={clienteId}
                          clinicaId={clinica.clinica._id}
                          sendRequest={sendRequest}
                          userData={userData}
                        />
                      )}
                    {showMainTabs === "fluxoAtendimento" &&
                      (auth.perm.includes("r-aval") ||
                        auth.perm.includes("r-orc") ||
                        auth.perm.includes("r-plt")) && (
                        <FluxoAtendimento
                          dadosOrcamento={dadosOrcamento}
                          clienteId={clienteId}
                          sendRequest={sendRequest}
                          token={auth.token}
                        />
                      )}

                    {showMainTabs === "documentos" &&
                      (auth.perm.includes("r-ates") ||
                        auth.perm.includes("r-pres")) && (
                        <Documentos
                          dadosPrescricao={dadosPrescricao}
                          dadosAtestado={dadosAtestado}
                          dadosImpresso={dadosImpresso}
                          clienteId={clienteId}
                          sendRequest={sendRequest}
                        />
                      )}

                    {showMainTabs === "historico" && (
                      <Historico
                        clienteId={clienteId}
                        sendRequest={sendRequest}
                        clienteData={userData}
                      />
                    )}

                    {showMainTabs === "debitos" &&
                      auth.perm.includes("r-deb") && (
                        <Debitos
                          clienteId={clienteId}
                          sendRequest={sendRequest}
                          nomeCliente={userData.nome}
                          dadosFatura={dadosFatura}
                          clienteData={userData}
                          dadosRecibo={dadosRecibo}
                        />
                      )}

                    {showMainTabs === "ficheiros" &&
                      auth.perm.includes("r-anex") && (
                        <Ficheiros clienteId={clienteId} />
                      )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
    </>
  );
}

export default Single;
